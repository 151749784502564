import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CalculationDemoBatchPage from "components/CalculationDemoBatchPage";
import SideBarMenu from "components/AstroComponents/SidebarMenu";
import { selectVerticalMenuExpand } from "store/slices/verticalMenuExpand";
import { SideBarElementType } from "components/AstroComponents/SidebarMenu";
import useMediaQuery from "@mui/material/useMediaQuery";
import json2mq from "json2mq";
import breakpoints from "styles/export.module.scss";
import styles from "./index.module.scss";

const CalculationDemoBatchMenu: React.FC = () => {
  const expand = useSelector(selectVerticalMenuExpand);
  const { t } = useTranslation();

  // separator line order is any number, not used
  const menu: SideBarElementType[] = [
    { order: 999, name: "", id: "", icon: "", type: "spacer" },
    { order: 1, name: t("DemoPage.ResultsSection.reference.result"), id: "refResultsPro", icon: "Folder", secondaryColor:"white", type: "listElement" },
    { order: 2, name: t("DemoPage.ResultsSection.reference.corrosion"), id: "refResultsProCorrosion", icon: "Science", secondaryColor:"white", type: "listElement" },
    { order: 3, name: t("DemoPage.ResultsSection.reference.saturation"), id: "refResultsProSaturation", icon: "Opacity", secondaryColor:"white", type: "listElement" },
    { order: 4, name: t("DemoPage.ResultsSection.reference.criticality"), id: "refResultsProCriticality", icon: "Speed", secondaryColor:"white", type: "listElement" },
    { order: 5, name: t("DemoPage.ResultsSection.reference.optimizationCorrosion"), id: "refResultsProOptimizationCorrosion", icon: "Savings", secondaryColor:"white", type: "listElement" },
    { order: 6, name: t("DemoPage.ResultsSection.reference.optimizationAntiScale"), id: "refResultsProOptimizationScale", icon: "Savings", secondaryColor:"white", type: "listElement" },
    { order: 999, name: "", id: "", icon: "", type: "line" },
  ];


  const mobileView = useMediaQuery(
    json2mq({
      minWidth: breakpoints.xlg,
    })
  );

  const getPage = () => {
    return <CalculationDemoBatchPage />;
  };

  return (
    <div className={styles.CalculationOnlineMenu}>
        <div className={styles.sideBar} style={{ width: expand ? "10vw" : "35px" }}>
          <SideBarMenu menuData={menu} inPageMenu={true} title="CalculatorDemoBatch.title"/>
        </div>
        
      <div className={styles.panel} style={{ width: expand ? "90vw" : "97.7vw" }}>
        <div className={styles.marginTop} style={{ marginTop: mobileView ? "30px" : "120px" }}>
          {getPage()}
        </div>
      </div>
    </div>
  );
};

export default CalculationDemoBatchMenu;
