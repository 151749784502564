import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "app/hooks";
import { selectVerticalMenu, saveVerticalMenu } from "store/slices/verticalMenu";
import { selectVerticalMenuExpand, saveVerticalMenuExpand } from "store/slices/verticalMenuExpand";
import Tooltip from "@mui/material/Tooltip";
import PungoClickable from "pungo-ui/PungoClickable";
import PungoLoginCircle from "pungo-ui/PungoLoginCircle";
import PungoIcon from "pungo-ui/PungoIcon";
import PSShortLogo from "assets/PungoIcons/ShortIcon.svg";
import { MenuOpen, DoubleArrow } from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";
import json2mq from "json2mq";
import breakpoints from "styles/export.module.scss";
import styles from "./index.module.scss";

export interface SideBarElementType {
  order: number;
  name: string;
  id: string;
  icon: string;
  primaryColor?: string;
  secondaryColor?: string;
  size?: number;
  type: string;
}

interface SidebarMenuProps {
  menuData: SideBarElementType[];
  inPageMenu?: boolean;
  title?: string;
}

const SideBarMenu: React.FC<SidebarMenuProps> = (props) => {
  const { menuData, inPageMenu = false, title = "sideBarMenu.section" } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const verticalMenuPosition = useSelector(selectVerticalMenu);
  const verticalMenuExpand = useSelector(selectVerticalMenuExpand);
  const [activeIndex, setActiveIndex] = useState(verticalMenuPosition);
  const [expandMenu, setExpandMenu] = useState(verticalMenuExpand);

  const mobileView = useMediaQuery(
    json2mq({
      minWidth: breakpoints.xlg,
    })
  );

  const redirectToMainPage = () => navigate("/");

  const getSiteNavigation = () => {
    return (
      <div className={styles.actionButtons}>
        <div className={styles.imageLink}>
          <PungoClickable onClick={redirectToMainPage}>
            <img src={PSShortLogo} alt="Pungo logo" className={styles.shortImage} />
          </PungoClickable>
        </div>
        <div className={styles.loginCircle}>
          <PungoLoginCircle />
        </div>
      </div>
    );
  };

  const getSamePageIndication = () => {
    return <div className={styles.refIndication}>{t(title)}</div>;
  };

  const handleClick = (order: number, id: string) => {
    setActiveIndex(order);
    if (!inPageMenu) {
      if (order === 0) {
        dispatch(saveVerticalMenu(0));
      } else if (order === 1) {
        dispatch(saveVerticalMenu(1));
      } else if (order === 2) {
        dispatch(saveVerticalMenu(2));
      } else if (order === 3) {
        dispatch(saveVerticalMenu(3));
      } else if (order === 4) {
        dispatch(saveVerticalMenu(4));
      }
    } else {
      const element = document.getElementById(id);
      if (element) {
        const rect = element.getBoundingClientRect();
        const scrollOffset = rect.top + window.scrollY - 180;
        window.scrollTo({
          top: scrollOffset,
          behavior: "smooth",
        });
      }
    }
  };

  const openClose = () => {
    setExpandMenu(mobileView && !expandMenu);
    dispatch(saveVerticalMenuExpand(mobileView && !expandMenu));
  };

  useEffect(() => {
    if (!mobileView) setExpandMenu(false);
    dispatch(saveVerticalMenuExpand(false));
  }, [mobileView]);

  let keyIndex = 1;
  return (
    <>
      <div className={styles.sidebar} style={{ width: verticalMenuExpand ? "10vw" : "35px" }}>
        {mobileView && (
          <div className={styles.burger} onClick={() => openClose()}>
            {expandMenu === true ? <MenuOpen sx={{ fontSize: 39, color: "#044075" }} /> : <DoubleArrow sx={{ fontSize: 31, color: "#044075" }} />}
          </div>
        )}

        {!inPageMenu && getSiteNavigation()}
        {inPageMenu && expandMenu && getSamePageIndication()}

        <div className={styles.contentsContainer}>
          <ul>
            {menuData.map((data, index) => {
              if (data.type === "listElement") {
                return (
                  <>
                    {data.order === activeIndex ? (
                      <li key={index} className={data.order === activeIndex ? styles.selected : styles.noSelected} onClick={() => handleClick(data.order, data.id)}>
                        <Tooltip title={data.name} arrow placement="top-start" slotProps={{ tooltip: { sx: { fontSize: "1em" } } }}>
                          <div>
                            <PungoIcon icon={data.icon} color={data.secondaryColor} />
                          </div>
                        </Tooltip>
                        {expandMenu && data.name}
                      </li>
                    ) : (
                      <li key={index} className={data.order === activeIndex ? styles.selected : styles.noSelected} onClick={() => handleClick(data.order, data.id)}>
                        <Tooltip title={data.name} arrow placement="top-start" slotProps={{ tooltip: { sx: { fontSize: "1em" } } }}>
                          <div>
                            <PungoIcon icon={data.icon} color={data.primaryColor} />
                          </div>
                        </Tooltip>
                        {expandMenu && data.name}
                      </li>
                    )}
                  </>
                );
              } else if (data.type === "title") {
                return (
                  <>
                    <div key={index} className={styles.title}>
                      <Tooltip title={data.name} arrow placement="top-start" slotProps={{ tooltip: { sx: { fontSize: "1em" } } }}>
                        <div>
                          <PungoIcon icon={data.icon} />
                        </div>
                      </Tooltip>
                      {expandMenu && data.name}
                    </div>
                  </>
                );
              } else if (data.type === "line") {
                keyIndex += 1;
                return (
                  <div key={keyIndex} className={styles.separatorLine}>
                    {" "}
                  </div>
                );
              } else if (data.type === "spacer") {
                keyIndex += 1;
                return (
                  <div key={keyIndex} className={styles.spacer}>
                    {" "}
                  </div>
                );
              } else {
                return <div> --- </div>;
              }
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default SideBarMenu;
